
import {Component, Vue} from "vue-property-decorator";
import {IProduct} from "@/apis/product/types";
import {Message} from "element-ui";
import config from "@/config";
import {getProductSortListApi} from "@/apis/category";
import {ICategory} from "@/apis/category/types";
import ProductBasic from "./components/ProductBasic.vue"
import ProductSpecs from "./components/ProductSpecs.vue"
import ProductDetails from "./components/ProductDetails.vue"
import ProductLogistics from "./components/ProductLogistics.vue"

import {queryProductSpecsValueListApi} from "@/apis/productSpecs";
import {ISpecs, ISpecsValue} from "@/apis/productSpecs/types";
import {createProductApi, getProductDetailApi, modifyProductApi} from "@/apis/product";
import {nextTick} from "vue";
import router from "@/router";

@Component({
  name: 'addProduct',
  components: {ProductBasic,ProductSpecs,ProductDetails,ProductLogistics}
})
export default class ProductAddPage extends Vue {

  config = config;
  //tab
  activeTabsName = 'first';
  // 新增修改表单
  modelForm: IProduct = {
    status:1
  }

  //分类下拉数据
  sortTreeList: ICategory[] = [];
  //商品规格下拉数据
  productSpecsValueList: ISpecsValue[] = [];
  //商品规格属性下拉数据
  productSpecsList: ISpecs[] = [];

  rules:any = {
    productName: [
      { required: true, message: '请输入商品名称', trigger: 'blur' }
    ],
    brandId: [
      { required: true, message: '请选择商品品牌', trigger: 'blur' }
    ],
    sortId: [
      { required: true, message: '请选择商品分类', trigger: 'blur' }
    ],
    productBannerPic: [
      { required: true, message: '请上传商品轮播图片', trigger: 'blur' }
    ],
    sort: [
      { required: true, message: '请选择排序', trigger: 'change' }
    ],
    productSkuVoList:[
      { required: true, message: '请生成商品SKU', trigger: 'change' }
    ],
    productSkuDtoList: [
      { required: true, message: '请选择商品规格', trigger: 'blur' }
    ],
    isParcel: [
      { required: true, message: '请选择物流运输', trigger: 'blur' }
    ]
  }

  /**
   * 全部分类
   */
  getSortTreeList() {
    getProductSortListApi().then(e => {
      this.sortTreeList = e.map((item: any) => {
        return {
          ...item,
          subProductSortList: item.subProductSortList || []
        }
      });
      this.$nextTick(() => {
        //@ts-ignore
        this.$refs.productBasicRef.sortTreeList = this.sortTreeList;
      })
    })
  }
  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
  }
  /**
   * 商品规格
   */
  getProductSpecsValueList() {
    try {
      queryProductSpecsValueListApi({status:1}).then(e => {
        this.productSpecsValueList = e;
        this.getDetail();

      })
    }catch (e) {
      this.getDetail();
    }
  }
  /**
   * tab
   * @param tab
   * @param event
   */
  handleTabsClick(tab: any, event: any) {
    // console.log(tab);

  }
  /**
   * submitFinish
   *
   * @param event
   */
  submitFinish(form:any,event: any){

    this.modelForm = Object.assign(this.modelForm, form);
    let bol = true;
    // @ts-ignore
    this.$refs.productBasicRef.$refs.modelForm.validate(async valid => {
      if (valid) {
        if(this.modelForm.productSkuDtoList||[].length>0){
          for (let item of this.modelForm.productSkuDtoList||[]) {
            if (!item.stock) {
              Message.error("请输入规格库存");
              this.activeTabsName = 'second'
              bol = false;
              return;
            }
            if (!item.skuPrice ) {
              Message.error("请输入规格价格");
              this.activeTabsName = 'second'
              bol = false;
              return;
            }
            if (item.skuPic == '' || !item.skuPic) {
              Message.error("请上传规格图片");
              this.activeTabsName = 'second'
              bol = false;
              return;
            }
          }
        }else {
          bol = false;
          Message.error('规格信息不完善！')
          this.activeTabsName = 'second'
        }

      } else {
        Message.error('基础信息不完善！')
        this.activeTabsName = 'first'
        bol = false;
      }
    })
    if (bol) {
      if (this.modelForm.id) {
        modifyProductApi(this.modelForm).then(e => {
          if (e) {
            Message.success('编辑成功！')
            this.$store.dispatch('delView', {path: '/productManage/addProduct',name: "addProduct"});
            router.push({path: '/productManage/product'})
          }
        })
      } else {
        createProductApi(this.modelForm).then(e => {
          if (e) {
            Message.success('新增成功！')
            this.$store.dispatch('delView', {path: '/productManage/addProduct',name: "addProduct"});
            router.push({path: '/productManage/product'})
          }
        })
      }
    }

  }
  /**
   * 商品详情信息
   * @param id
   */
  getDetail(){
      if(!this.modelForm.id)
        return
    getProductDetailApi(this.modelForm.id).then(e => {
      this.modelForm=e;
      if(this.modelForm.productSkuVoList){
        this.modelForm.productSkuDtoList=this.modelForm.productSkuVoList;
      }
    })
  }
  /**
   * 商品详情信息,商品规格信息,商品基础信息
   * @param form
   */
  handleNext(form:IProduct,tab:string) {
    this.modelForm=Object.assign( this.modelForm,form);
    this.activeTabsName=tab;

  }
  async created() {
    await this.getSortTreeList();
    const {id} = this.$route.query;
    this.modelForm.id=id;
    await this.getProductSpecsValueList();
    // await this.getDetail();

  }
}
