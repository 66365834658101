import { render, staticRenderFns } from "./addProduct.vue?vue&type=template&id=578cf035&scoped=true"
import script from "./addProduct.vue?vue&type=script&lang=ts"
export * from "./addProduct.vue?vue&type=script&lang=ts"
import style0 from "./addProduct.vue?vue&type=style&index=0&id=578cf035&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "578cf035",
  null
  
)

export default component.exports